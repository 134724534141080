<template>
    <div>
        <div class="font-semibold mb-2">Categories</div>
        <div v-for="(cat,i) in categories" :key="i" class="border-t border-gray-400 py-2">
            <div>
                <span class="text-sm rounded-full bg-green-500 font-semibold text-white px-1">{{ cat.price }},-</span>
                <span class="ml-2 font-semibold cursor-pointer" :class="{'line-through': !cat.active}" @click="editCategory(cat)">{{ cat.name }}</span>
                <fa-icon v-if="cat.travel" icon="compass" class="ml-2" />
                <span class="text-sm ml-2">[ver. {{ catVersion(cat.versionDate) }}]</span>
                <span class="text-sm ml-2">New: {{ cat.newVersionQuestionDiff }}</span>                
            </div>
            <div class="mt-1 h-2 bg-yellow-200 border border-gray-600" :style="'width: ' + catVolumPercent(cat.gameQuestionCount)*3 + '%'"> 
            </div>
        </div>
    </div>

    <EditCategory ref="editCategoryDlg" />
</template>

<script>
import axios from 'axios';
import { catVersion } from '@/utils.js';
import EditCategory from '@/components/EditCategory.vue';

export default {

    components: {
        EditCategory
    },
    props: {
        isAdmin: Boolean,
    },    
    data() {
        return {
            categories: [],
        }
    },
    created() {
        this.getCategories();
    },
    methods: {
        catVersion,
        getCategories: function() {
            axios.get('/question/categories').then(res => {                
                this.categories = res.data;
            });
        },
        editCategory: function(cat) {
            this.$refs.editCategoryDlg.open(cat).then(() => {
                this.getCategories();  // refresh
            });
        },
        catVolumPercent: function(count) {
            let totCount = 0;
            this.categories.forEach((cat) => {
                totCount += cat.gameQuestionCount;
            });
            return Math.round(count/totCount*100);
        }
    }

}
</script>