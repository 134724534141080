<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content max-w-sm text-center">

                <div v-if="closable" class="flex justify-end -mr-2 -mt-2 mb-0" @click="close(false)">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>

                <div class="modal-header">
                    Scan QR
                </div>

                <div class="mb-2">
                    Vennligst scan QR-koden til spillet
                </div>

                <div class="mx-auto border" :style="'width:' + this.width + 'px; height:' + this.height + 'px'">
                    <QrcodeStream @detect="qrDetect" @camera-on="qrStreamReady" @error="qrStreamError"/>
                </div>                                
                
                <div class="mt-4 text-lg" v-if="startingCamera">
                    Starter kamera...
                </div>
                 
                <!--
                <video autoplay muted playsinline class="my-2 mx-auto border border-gray-400"></video>
                -->
                
                <div class="mt-2 border border-gray-400 bg-red-200 p-2 text-gray-900" v-if="errMsg" v-html="errMsg"></div>
                
                <!--
                <div v-if="!startingCamera" class="modal-footer flex justify-evenly">
                    <div v-if="checkingQR">
                        <button class="btn py-2 px-6">Vennligst vent<span class="ml-4 animate-spin inline-block">⏳</span></button>
                    </div>
                    <div v-else>
                        <button class="btn py-2 px-6" @click="takePicture()">Ta bilde</button>
                    </div>
                </div>
                -->

                <details class="mt-2">
                    <summary>Problemer med å scanne QR-koden?</summary>
                    <div class="mt-2">                         
                        <div class="text-sm"> 
                            Skriv inn spillets unike kode. Den finnes i kvitterings-epost og den vises i menyen hos andre spillere.
                        </div>
                        <form @submit.prevent="codeInput()" class="mt-2 flex gap-x-2 justify-center">
                            <input placeholder="5 tegn" type="text" class="w-20" v-model="code" required>     
                            <button class="btn">OK</button>                   
                        </form>
                    </div>
                </details>
                                
            </div>
        </div>
    </teleport>
</template>

<script>
import axios from 'axios';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    props: {
        closable: Boolean
    },
    components: {
        QrcodeStream, 
    },    
    data() {
        return {
            width: 300,
            height: 300,
            show: false,
            resolve: null,
            errMsg: '',
            startingCamera: true,
            checkingQR: false,
            videoStream: null,
            code: '',
        }
    },
    methods: {
        qrStreamError: function(error) {            
            this.errMsg = error.message;
            switch (error.name) {
                case 'NotAllowedError': this.errMsg = 'Du må gi appen tillatelse til å bruke kameraet'; break;
            }
        },
        qrStreamReady: function(/*capabilities*/) {
            //console.log(capabilities)
            this.startingCamera = false;
        },
        qrDetect: function(data) {            
            if (data.length) {
                const uuid = this.parseUUID(data[0].rawValue);                     
                axios.get('/verifyBoard?uuid=' + uuid).then(res => { 
                    this.close(res.data.board);
                }).catch(() => {                
                
                });    
            }         
        },
        takePicture: function() {
            this.checkingQR = true;
            let video = document.querySelector('video');
            video.pause();
            
            var canvas = document.createElement('canvas');     
            canvas.width = this.width;
            canvas.height = this.height;
            canvas.getContext('2d').drawImage(video, 0, 0, this.width, this.height);              

            canvas.toBlob((blob) => {
                let data = new FormData();
                data.append('file', blob);
                axios.post('/qr/parse', data).then((res) => {    
                    this.checkingQR = false;  
                    const uuid = this.parseUUID(res.data.text);                
                    axios.get('/verifyBoard?uuid=' + uuid).then(res => { 
                        this.close(res.data.board);
                    }).catch(() => {
                        //console.log('invalid scan: ' + text);
                        this.errMsg = 'Vennligst forsøk igjen';                        
                        video.play();
                    }); 
                });
            });
        },
        parseUUID: function(qrUrl) {
            const arUrl = qrUrl && qrUrl.indexOf('uuid=') > 0 ? qrUrl.split('=') : [];                    
            const uuid = arUrl.length > 1 ? arUrl[1] : '';     
            return uuid;               
        },
        codeInput: function() {           
            axios.get('/verifyBoard?code=' + this.code).then(res => {  // workaround/fallback if camera not working
                this.close(res.data.board);
            });
        },
        getUUID: function(uuid, isSim, forceScan) {           
            const isDev = location.hostname === 'localhost';
            const isAdmin = uuid === process.env.VUE_APP_SHOW_IN_APP_UUID;
            if ((isSim || isAdmin || isDev) && !forceScan) {  // skip QR scan and use provided uuid (simulator, admin user or 'show in app')
                axios.get('/verifyBoard?uuid=' + uuid).then(res => { 
                    this.close(res.data.board);
                });
            }
            else {  
                this.show = true;    
                this.startingCamera = true;                           
                /*navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: {
                        width: this.width,
                        height: this.height, 
                        facingMode: 'environment'            
                    }
                }).then((stream) => {
                    document.querySelector('video').srcObject = stream;    
                    this.startingCamera = false;
                    this.videoStream = stream;
                });   */                              
            }
            return new Promise((resolve) => {                    
                this.resolve = resolve;
            });             
        },
        close: function(res) {            
            this.errMsg = '';
            try {
                let video = document.querySelector('video');
                if (video) {
                    video.srcObject = null;  // to prevent camera freeze..
                }
                if (this.videoStream) {
                    this.videoStream.getVideoTracks().forEach(function(track) {
                        track.stop();
                    });   
                    this.videoStream = null;                 
                }   
            } catch (err) {
                console.error(err);
            }
            this.show = false;
            this.resolve(res);  
        }
    }
}
</script>

<style scoped>

details summary {
    @apply cursor-pointer;
}

</style>