<template>
    <div> 
        <div class="heading">Innledning</div>
        <div class="paragraph">
            Ainou Mini er et kunnskapsspill for inntil 10 deltakere. 
            Det medfølger 6 kategorier. I tillegg kan flere kategorier kjøpes her i appen. 
            Man kan også koble kategorier fra brettspill-versjonen dersom man har dette. 
        </div>

        <div class="heading">Forberedelser</div>
        <div class="paragraph">
            Appen må installeres på mobiltelefonen til alle spillerne. Ved første gangs installasjon scannes QR-koden på undersiden av spinneren med mobilkameraet. 
            Åpne lenken og følg instruksjonene for å installere. Dette gjøres <span class="underline">kun første gang man spiller</span>. Neste gang starter man appen direkte. 
            Merk at man bruker samme app for både brettspill- og miniversjon.
        </div>
        <div class="paragraph">
            Den første som starter appen blir organisator og er ansvarlig for å velge kategorier (dersom man har flere enn 6 tilgjengelig) og starte spillet.            
            Når spillet startes bestemmer man hvor lenge man ønsker å spille (fra 10 minutter til 2 timer). Spillet avsluttes automatisk når tiden er ute.
        </div>
        <div class="paragraph">
            Vi anbefaler å plassere spinneren på et flatt underlag og bruke begge hender som vist under når man skal snurre.            
            <img src="/images/travel_spinner_howto.png" class="mt-3 mx-auto">                        
        </div>

        <div class="heading">Spillets gang</div>
        <div class="paragraph">            
            Den yngste starter spillet med å snurre og trykker deretter i appen på det feltet spinneren stoppet på.
            Et spørsmål vises på de andre spillernes mobiltelefon og leses opp for spilleren. Korrekt svar vises under spørsmålet. 
            Når svar er avgitt trykker en av de andre spillerne på 👍🏻 eller 👎🏻. Ved korrekt svar får spilleren poeng.
            Turen går deretter videre til neste spiller (i retning med klokka).
        </div>
        <div class="paragraph">           
            <span class="underline">Sjansefelt</span>: 
            Når spinneren stopper på et felt med spørsmålstegn trykker man på tilsvarende felt i den nederste raden i appen. Man får da et sjansekort før selve spørsmålet.
            Sjansekortene vil kunne gi deg ekstrapoeng og ta poeng fra andre, men du kan også risikere å miste poeng.                               
        </div>
        <div class="paragraph">
            <span class="underline">Fellesrunde</span>: 
            Dersom spinneren stopper på et av de mørkeblå feltene er det klart for fellesrunde. Alle spillerne avgir da et svar på sin egen mobiltelefon.
            Det er spilleren(e) som kommer nærmest riktig svar som får poeng.
            Intervallet man avgir svaret innenfor er ulikt for de ulike aldersgruppene, slik at vanskelighetsgraden blir enklere for de yngre spillerne.
            Poengene for fellesrunder vises først når spillet avsluttes. Etter en fellesrunde får spilleren snurre på nytt.
        </div>
        <div class="paragraph">
            Underveis i spillet kan det dukke opp jokere og røvere. Turen går da videre til neste spiller.
        </div>

        <div class="heading">Avslutning</div>
        <div class="paragraph"> 
            Når den definerte tiden er ute vil spillet avsluttes automatisk. Man kan også avslutte manuelt i menyen om man ønsker det. Totalt antall poeng (inkludert fellesrundepoeng) og din plassering vises da på skjermen.
        </div>

        <div class="heading">Single-modus</div>
        <div class="paragraph"> 
            Det er mulig å bruke spillet i single-modus. Når kun én person er innlogget i appen vil både spørsmål og svar (skjult) vises på telefonen.
            Sjekk svaret ditt og gi deg selv poeng ved korrekt svar (NB: i single-modus vil du alltid få poeng i fellesrunder).
        </div>        
    </div>
</template>

<script>

export default {

}

</script>

<style scoped>
.heading {
    @apply font-semibold text-lg mb-0.5;
}
.paragraph {
    @apply text-base mb-4;
}
</style>