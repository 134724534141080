<template>
    <div> 
        <button @click="getActiveGames" class="btn mb-1">Refresh</button>
        <table>
            <tbody>
                <tr>
                    <td>Active games</td>
                    <td>{{ games.length }}</td>
                </tr>
                <tr v-if="games.length > 0">
                    <td>Total players</td>
                    <td>{{ playerCount }}</td>
                </tr>
            </tbody>
        </table>

        <div class="overflow-x-auto border-t mt-2 pt-2" v-if="games.length > 0">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Created</th>
                        <th>Players</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(game, i) in games" :key="i">
                        <td class="text-right">{{game.id}}</td>
                        <td>{{formatDateTime(game.gameCreated)}}</td>
                        <td class="text-right">{{ game.activePlayers }}</td>
                        <td><fa-icon v-if="game.isTravel" icon="compass"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { formatDateTime } from '@/utils.js';

export default {
    data() {
        return {
            games: [],
        }
    },
    computed: {
        playerCount() {
            let count = 0;
            for (let i = 0; i < this.games.length; i++) {
                count += this.games[i].activePlayers;
            }
            return count;
        }
    },
    created() {
        this.getActiveGames();
    },
    methods: {
        formatDateTime,
        getActiveGames: function() {
            axios.get('/games').then(res => {                
                this.games = res.data;
                console.log(res.data);
            });
        }
    }
}
</script>

<style scoped lang="postcss">
    table td, table th {
        @apply px-2;
    }
</style>